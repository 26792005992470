import * as React from "react";
import PropTypes from "prop-types";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

const FeatureGrid = ({ gridItems }) => (
  <div className="columns is-multiline">
    {gridItems.map((item) => (
      <div className="column is-6">
        <section className="section">
          <div className="columns is-featured tile is-child box is-white has-text-centered">
          <div className="column is-6">
            <div
              style={{
                width: "100%",
                display: "inline-block",
              }}
            >
              <PreviewCompatibleImage imageInfo={item} />
              <p><br/><b>{item.affiliation}<br/>{item.name}</b></p>
              </div>
              </div>
              <div className="column is-6">
                <p>{item.text}</p>
            </div>
          </div>
          
        </section>
      </div>
    ))}
  </div>
);

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      affiliation: PropTypes.string,
      name: PropTypes.string,
      text: PropTypes.string,
    })
  ),
};

export default FeatureGrid;
