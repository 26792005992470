import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Features from "../components/Features";
import Testimonials from "../components/Testimonials";
import Pricing from "../components/Pricing";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import FullWidthImage from "../components/FullWidthImage";

// eslint-disable-next-line
export const MessagePageTemplate = ({
  image,
  intro,
  fullImage,
}) => {

  return (
    <div className="content">
      <section className="section section--gradient" style={{backgroundColor: "#E4F2FC"}}>
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <h3 className="has-text-weight-semibold is-size-2">
                  応援メッセージ
                </h3>
                <p>ご支援を頂いている方々から応援メッセージいただきました！</p>
              </div>
            </div>
            <div className="columns">
              <div className="column is-12">
                <Features gridItems={intro.blurbs} /> 
                </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

MessagePageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const MessagePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <MessagePageTemplate
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

MessagePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default MessagePage;

export const messagePageQuery = graphql`
  query MessagePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        intro {
          blurbs {
            image {
              childImageSharp {
                gatsbyImageData(width: 240, quality: 64, layout: CONSTRAINED)
              }
            }
            affiliation
            name
            text
          }
        }
      }
    }
  }
`;
